import React from 'react';
import Cookies from 'universal-cookie';

import NavHead from '../NavHead';
import CookieWarning from '../CookieWarning';
import LayoutContainer from '../EditorPanel/ManageSections/LayoutContainer';
import Image from '../Image';

const cookies = new Cookies();

class ErrorView extends React.Component {

  constructor(props) {

    super(props);

    cookies.get('cookiesAccepted');

    this.state = {
      matches: null,
      isScrolling: false,
    };

    this.sentinel = React.createRef();

    this.updateMatches = this.updateMatches.bind(this);
    this.documentReady = this.documentReady.bind(this);
    this.handleScroll = this.handleScroll.bind(this);

  }

  componentDidMount() {

    const breakpoint = '769px';
    this.mediaQueryList = window.matchMedia(`(min-width: ${breakpoint})`);
    this.mediaQueryList.addListener(this.updateMatches);

    this.setState({
      matches: window.matchMedia(`(min-width: ${breakpoint})`).matches,
    });

    if (this.props.pageContext.navigation.styles.fixed_top) {

      const elem = this.sentinel.current;
      if (elem) {

        const observer = new IntersectionObserver(this.handleScroll);
        observer.observe(elem);

      }

    }

  }

  componentWillUnmount() {

    if (this.mediaQueryList) {

      this.mediaQueryList.removeListener(this.updateMatches);

    }

  }

  updateMatches() {

    this.setState({
      matches: this.mediaQueryList.matches,
    });

  }

  handleScroll(entries) {

    if (this.props.pageContext.navigation.styles.fixed_top) {

      this.setState({
        isScrolling: !entries[entries.length - 1].isIntersecting,
      });

    }

  }

  documentReady(callbackFunction) {

    if (typeof document !== 'undefined') {

      if (document.readyState !== 'loading') {

        callbackFunction();

      } else {

        document.addEventListener('DOMContentLoaded', callbackFunction);

      }

    }

  }

  render() {

    let ready = false;
    if (typeof document !== 'undefined') {

      this.documentReady(() => {

        ready = true;

      });

    }

    const text = this.props.pageContext.language === 'FI' ? 'Sivua ei löydy' : 'Page not found';
    const overlay = this.props.pageContext.navigation.styles.overlay || false;

    return (
      <div className="render-wrapper">
        <div ref={this.sentinel} />
        {
          this.props.pageContext.page.sections
          && this.props.pageContext.page.sections[1]
          && this.props.pageContext.page.sections[1].styles
          && this.props.pageContext.page.sections[1].styles.image
          && this.props.pageContext.page.sections[1].styles.image.id
          && overlay
          && (
            <Image
              id={this.props.pageContext.page.sections[1].styles.image.id}
              sizes="100vw"
              alt="Background"
              imageStyleName="sectionBackgroundImageFull404"
              images={this.props.pageContext.images}>
            </Image>
          )
        }
        <NavHead isScrolling={this.state.isScrolling} pageContext={this.props.pageContext} />
        <div style={{
          backgroundColor: '#fff',
          width: '100%',
          color: '#444',
          marginTop: overlay ? '200px' : '20px',
          fontSize: '30px',
          fontWeight: 600,
          height: '120px',
          textAlign: 'center',
        }}>
          {text}
        </div>
        {
          (
            this.props.pageContext.cookie
            && this.props.pageContext.cookie.active === true
            && cookies
            && cookies.cookies
            && cookies.cookies.cookiesAccepted === undefined
            && ready === true
          )
          && (
            <CookieWarning
              colors={this.props.pageContext.themeData.colors}
              cookie={this.props.pageContext.cookie}
            />
          )
        }
        {
          this.props.pageContext.footer
          && (
            <LayoutContainer
              pageContext={this.props.pageContext}
              key={`Layout_${this.props.pageContext.footer._id}`}
              section={this.props.pageContext.footer}
              matches={this.state.matches}
            />
          )
        }
      </div>
    );

  }

}

export default ErrorView;
